import React from 'react'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"


library.add(fab)

const Social = (props) => {
    const { siteUrl } = useSiteMetadata()
    return (
        
        <div className="social">
            <ul>
                <li>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}/${props.post.slug}`} target="_blank" rel="noreferrer" ><FontAwesomeIcon aria-label="facebook" icon={["fab", "facebook-f"]} /></a>
                </li>
                <li>
                    <a href={`http://www.twitter.com/share?url=${siteUrl}/${props.post.slug}`} target="_blank" rel="noreferrer" ><FontAwesomeIcon aria-label="twitter" icon={["fab", "twitter"]} /></a>
                </li>
                <li>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}/${props.post.slug}`} target="_blank" rel="noreferrer" ><FontAwesomeIcon aria-label="linkedin" icon={["fab", "linkedin-in"]} /></a>
                </li>
            </ul>
        </div>
    )
}

export default Social