import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { Badge, Row, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"



// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

//import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Social from "../components/social"
import Sidebar from "../components/sidebar"

library.add(fas)

const BlogPostTemplate = ({ data: { previous, next, post, allPosts } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  
  return (
    <Layout posts={post.date}>
      <Seo title={post.title} description={post.excerpt} />
     
      <Row>
        <div className="col-lg-8">
          <Card className="border-0">
            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.fluid && (
              <Image className="featuredimg"
                fluid={featuredImage.fluid}
                alt={featuredImage.alt}
              />
            )}
            <Card.Body className="px-0">
              <div><small>{post.date}</small></div>
              <h4 itemProp="headline">{parse(post.title)}</h4>
              {post.categories.nodes !== null ? post.categories.nodes.map((category, index) => (
                <Link key={index} to={`/category/${category.slug}/`}><Badge pill variant="secondary" >{category.name}</Badge></Link>
              )) : ""}

              {!!post.content && (
                <section>
                  {!!post.excerpt &&(
                    <div className="blog-info excerpt-info" itemProp="articleExcerpt">{parse(post.excerpt)}</div>
                  )}
                  <Social post={post} />
                  <div className="blog-info" itemProp="articleBody">{parse(post.content)}</div>
                </section>
              )}
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-4">
          <Sidebar isSinglePage posts={allPosts}/>
        </div>
      </Row>

      <nav className="blog-post-nav">
        <div className="row"
          style={{
            marginTop:20,
          }}
        >
          <div className="col-lg-6 col-md-6 col-6">
            {previous && (
              <h5 className="singlepostbtext mb-2"><span className="mr-3"><FontAwesomeIcon aria-label="arrow-left" icon={["fas", "arrow-left"]} /></span> PREV POST</h5>
            )}
            {previous && (
              <h6 className="sbt"><Link to={previous.uri} rel="prev">
                 {parse(previous.title)}
              </Link></h6>
            )}
          </div>

          <div className="col-lg-6 col-md-6 col-6 text-right">
            {next && (
              <h5 className="singlepostbtext mb-2">NEXT POST <span className="ml-3"><FontAwesomeIcon aria-label="arrow-right" icon={["fas", "arrow-right"]} /></span></h5>
            )}
            {next && (
              <h6 className="sbt"><Link to={next.uri} rel="next">
                {parse(next.title)} 
              </Link></h6>
            )}
          </div>
        </div>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      slug
      categories {
        nodes {
          id
          slug
          name
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    # this gets all the post except current post
    allPosts: allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {nin: "Uncategorized"}}}}, id: {ne: $id}}
      sort: { fields: [date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          excerpt
          content
          title
          date(formatString: "MMMM DD, YYYY")
          slug
          categories {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
